.app-window {
	table {

		width: 100%;
		border-right: 1px solid $table-border-color;
		border-bottom: 1px solid $table-border-color;

		thead {

			background: $table-header-background;

			th,
			td {
				font-weight: bold;
				text-transform: uppercase;
			}
		}

		tbody {


		}

		tr {

		}

		th,
		td {
			vertical-align: top;
			border-left: 1px solid $table-border-color;
			border-top: 1px solid $table-border-color;
			padding: 15px 15px;

			h4 {
				font-size: 18px;
			}

			p, h1, h2 h3, h4, h5, h6, li {
				margin-top: 0;
				margin-bottom: 12px;
			}
		}

		.table-list {
			list-style: none;
			list-style-type: none;
			margin: 0;
			padding: 0;

			li {

			}

			&--icons {
				li {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					padding: 4px 0;
					margin-bottom: 0;
					position: relative;

					.material-icons {
						margin-right: 8px;
						color: $body-color;
					}
				}
			}
		}

	}

	.table-container {
		padding: 0;
	}

	.table-holder {
		padding: 0 !important;
	}

	.table-section {
		margin-bottom: 40px;
	}

	.table-suffix {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		padding-top: 15px;
		padding-bottom: 15px;

		a {
			display: flex;
			align-items: center;
		}

		svg {
			width: 15px;
			margin-left: 8px;
		}
	}
}