.pagination {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;

  &__buttons {
    display: flex;
    justify-content: center;

    button {
      min-width: 30px;
      width: 30px;
      height: 30px;
      margin-left: 8px;
      margin-right: 8px;
      border-radius: 50%;
      padding: 0;

      svg {
        width: 0.75rem;
        height: auto;
      }
    }
  }
}