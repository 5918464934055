.field-holder {
	margin-bottom: 20px !important;
}

.panel-contents .field-holder {
	margin-bottom: 28px !important;
}

.field-help-text {
	margin: 5px 0 5px !important;
	font-size: 12px;
	font-style: italic;
	color: #747474;
}

.action-buttons {
	button {
		margin-bottom: 20px;
	}
}

.button-flex {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 25px;

	> button,
	> span {
		margin: 0 20px 20px 0;
	}
}

.form-message {
	font-weight: 600 !important;

	&--bad {
		color: $error-color;
	}
}

input.Mui-disabled,
textarea.Mui-disabled,
select.Mui-disabled {

	background: $light-grey;

}


///
/// STEPS FORMS CONTENTS
///
.step-forms-container {

	border-radius: $border-radius;
	padding-bottom: 20px;

	.form-panels-container {
		padding: 20px;
		overflow-y: auto;
		margin: 0;
		max-height: 65vh;
	}

	.form-panel,
	.form-panel.Mui-expanded {
		margin: 0 !important;
	}

	.form-panel:first-child {
		.panel-heading {
			border-radius: $border-radius $border-radius 0 0;
		}
	}

	.form-panel:last-child {
		.panel-heading {
			border-radius: 0 0 $border-radius $border-radius;
		}
	}

	.form-panel.Mui-expanded:last-child {
		.panel-heading {
			border-radius: 0 0 0 0;
		}
	}

	.form-panel {
		&__error {
			border-style: solid;
			border-color: red;
			border-width: 1px;
		}
	}

	.panel-heading {
		background: $accordion-header-bg;
		color: $accordion-header-color;
		min-height: 1px !important;

		> div {
			margin: 0 !important;
		}

		svg,
		h3 {
			color: $white;
		}

		h3 {
			font-size: 14px;
			text-transform: uppercase;
			font-weight: 400;
			margin-bottom: 0;
		}
	}

	.panel-contents {
		padding: 40px 20px;
	}

	.form-footer {
		padding: 15px 20px;
		width: 100%;
		text-align: right;
		border-top: 1px solid rgb(226, 218, 218);

		&--fixed {
			padding: 40px 20px 40px;
			position: fixed;
			bottom: 0;
			width: 90%;
			left: 5%;
			display: flex;
			justify-content: flex-end;
			background: white;
			background: rgb(255,255,255);
			background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 73%, rgba(255,255,255,0) 100%);
			z-index: 99;
		}

		button {
			margin-left: 10px;
			position: relative;
			right: 0;

			svg {
				margin-right: 10px;
			}

			.spinner {

				margin-right: 10px;

				.circle {
					padding: 0;
				}
			}
		}
	}

}

.tab-loading {
	position: relative;

	.spinner.spinner--sm {
		position: absolute;
		z-index: 1;
		left: 46%;
		top: 50px;
	}
}


.step-forms-container .tab-loading .form-panels-container {
	opacity: 0.3;
}

.preview-buttons {
	padding: 10px 20px 10px;
	text-align: right;
}

//
// Grouped select
//
.grouped-select {

	.label--shrink {
		font-size: 18px;
		position: relative;
		display: block;
	}

}

select {

	&:focus {
		background: transparent !important;
	}

}

.autocomplete {
	position: relative;

	.hover-search-results {
		position: absolute;
		z-index: 10;
		width: 100%;

		.item {
			display: block;
			padding-top: 8px;
			padding-bottom: 8px;
		}
	}
}