.separator {
	display: block;
	background: #DDD;

	&--horizontal {
		margin: -3px 0 8px 0;
		width: 100%;
		height: 1px;
	}

	&--vertical {
		margin: 0 8px;
		width: 1px;
		height: 100%;
	}
}
