.upload-field{

  border: 1px solid $light-grey;
  background: $white;
  border-radius: $border-radius;
  padding: #{$spacing-unit * 2}px;
  position: relative;

  .icon{
    width: 1.5rem;
    height: 1.5rem;
  }

  input[type=file]{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    &:hover{
      cursor: pointer;
    }
  }
  .inline{
    flex-wrap: nowrap;
  }

  .upload-field {
    margin: 5px 0!important;
  }
}

.attach{
  label{
    margin-bottom:1em;
  }

  margin-bottom:1em;
}

.uploaded-image-details {
  margin-top: 5px;
  border: 1px solid $light-grey;
  background: $white;
  border-radius: $border-radius;
  padding: #{$spacing-unit * 2}px;
  position: relative;

  .uploaded-image-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__icon {
      width: 60px;

      img {
        width: 100%;
      }
    }

    &__details {
      flex-grow: 1;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}