/* simple modal styling */
.modal {
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);

    .paper {
        position: relative;
        border-radius: $border-radius;
        box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12);
        outline: none;
        background-color: $modal-background;
        color: $modal-foreground;
        padding:#{$spacing-unit * 5}px;
        padding-right:#{($spacing-unit * 5)-12}px;
    }

    &:focus{
        outline: 0px;
    }
}


/* styling for the primary version of a modal */
.modal.modal--primary {
    width: 90%;
    max-width: 800px;
    max-height: 100vh;
    position: relative;
    .paper {
        border-radius: 0;
        padding: 0;
        max-width: 800px;
    }

    .modal-header {
        display:flex;
        flex-direction: row;
        border-top-right-radius: $border-radius;
        border-top-left-radius: $border-radius;
        background-color: $modal-background-header;
        color: $modal-foreground-header;
        padding: #{$spacing-unit * 3}px #{$spacing-unit * 4}px;
        box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12);
        max-width: 800px;

        .inline{
            margin-bottom: 0.2em;
        }
        .header {
            color: $modal-foreground-header;
        }

        .subtitle {
            color: $modal-foreground-header;
        }

        .title-container{
            width: 80%;
        }
    }
    
    .modal-content {
        padding: #{$spacing-unit * 3}px #{$spacing-unit * 4}px;
        flex-grow: 1;
        overflow: auto;
        &.paper{
            max-height: 85.5vh;
        }
    }
    .modal-footer{
        background-color: $modal-background;
        position: inherit;
        padding: 0 #{$spacing-unit * 3}px #{$spacing-unit * 4}px;
        border-radius: 0  0 $border-radius $border-radius;
        border-top: 0;
        bottom: 0.5em;
    }
}

.modal.modal--primary .modal-header {
    flex-direction: column;
}
