.welcome-screen {

	min-height: 100vh;
	display: flex;
	flex-direction: column;
	background-color: $welcome-bg-color;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	.welcome-main {
		flex-grow: 1;
		display: flex;
		align-items: center;
		padding: 100px 0;
	}

	.welcome-card {
		padding: 32px 24px;
	}

	.login-popup {

		h3 {
			margin-bottom: 20px;
		}

		p {
			margin-bottom: 24px;
			line-height: 1.7;
		}

	}
}
