.packages {

	padding-top: 20px;

	table {
		width: 100%;
		border-left: 1px solid $border-color;
		border-top: 1px solid $border-color;


		td {
			border-right: 1px solid $border-color;
			border-bottom: 1px solid $border-color;
			padding: 10px 15px;
			vertical-align: top;
		}
	}

	p {
		margin: 0;
	}

	h6 {
		font-size: 18px;
		margin: 0 0 5px 0;
	}

	.package__price {

		.currency {
			font-size: 14px;
			position: relative;
			top: -12px;
		}

		.price {
			font-size: 32px;
			font-weight: bold;
		}

		.duration {
			font-size: 14px;
		}

	}

	.package__title-cell {
		width: percentage(1/2);

		.strong {
			font-size: 16px;
			color: $body-color-strong;
		}

		span {
			display: block;
		}
	}

	.package__plan-cell {
		width: percentage(1/6);
		text-align: center;
	}

}