.view-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	padding-bottom: 15px;
	border-bottom: 1px solid $headings-border-color;
	margin-bottom: 40px;

	h1, h2, h3, h4, h5, h6 {
		margin: 0;
	}

	p, button {
		margin: 0;
	}

	button {
		background: $headings-button-bg;
		border-color: $headings-button-bg;
		color: $headings-button-color;

		&:hover {
			color: $headings-button-bg;
			background: $headings-button-color;
		}
	}

	&__inner {
		display: flex;
		align-items: center;
	}
}
