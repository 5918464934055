$primary-color: #002B3C;

/// HEADER
$header-bg: #002B3C;
$header-color: #FFFFFF;
$header-active-color: #808080;


// FOOTER
$footer-border-color: #dddddd;
$footer-bg: #002B3C;
$footer-color: #FFFFFF;
$footer-active-color: #458596;


// BODY Colors
$body-color-strong: #484848;
$body-color: #637381;

//MODAL HEADERS
//modal colours
$modal-background: #FAFAFA;
$modal-foreground: #000000;
$modal-background-header: #7DCFE2;
$modal-foreground-header: #FFFFFF;


// HEADINGS
$headings-border-color: #E7E7E7;
$headings-button-color: #FFFFFF;
$headings-button-bg: #e24301;


// TABLES
$table-border-color: #dddddd;
$table-header-background: #EEEEEE;

//SPACING
$spacing-unit: 8;


//INPUTS
$border-radius: 3px;
$input-border: rgba(0, 0, 0, 0.5);


//ACCORDIONS
$accordion-header-color: #002B3C;
$accordion-header-bg:#7DCFE2;

//TABS ACTIVE


// WELCOME SCREEN
$welcome-bg-color: #002B3C;
$welcome-footer-color: #FFFFFF;





$link-color: #191919;
$error-color: #a80000;
$active-color: #35C69B;


$primary-color-1: #71B8FF;
$secondary-color-1: #7CECED;
$secondary-color-2: #00CFCA;

$alert-color: #FF7672;
$warning-color: #FFCB66;

$denim-primary: #7d9cc4;
$denim-secondary: #a1bbdc;
$almost-white: #FAFAFA;
$white: #ffffff;
$light-grey: #eeeeee;

$border-color: #dddddd;
$dark-grey: #000000;

