.footer {
	display: flex;
	background: $footer-bg;
	color: $footer-color;
	border-top: 1px solid $footer-border-color;
	padding: 20px 0;

	&__grid {
		justify-content: space-between;
		align-items: flex-end;
	}

	.footer-group {
		padding: 0 20px 0 0;


		&__lang-buttons {

			button {
				border: 1px solid $border-color;
				border-right: 0;
				border-radius: 0;
				color: $white;

				&:first-child {
					border-radius: $border-radius 0 0 $border-radius;
				}

				&:last-child {
					border-radius: 0 $border-radius $border-radius 0;
					border-right: 1px solid $border-color;
				}

				&.active {
					background: $primary-color;
					border-color: $primary-color;
					color: $white;
				}
			}

		}
	}

	h3 {
		font-weight: 300;
	}

	h4 {
		border-bottom: 1px solid #ffffff;
		padding-bottom: 6px;
		text-transform: uppercase;
		font-size: 12px;
	}

	ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	a {
		color: #FFFFFF;
		text-decoration: none;
		font-weight: 400;
	}


	//&--black {
	//	border-top: 1px solid $border-color;
	//	background: $app-footer-bg;
	//	color: $body-color;
	//
	//	h4 {
	//		border-bottom: 0;
	//	}
	//
	//	a {
	//		color: $body-color;
	//	}
	//}
}

.app-main {

	.footer-group {

		&__lang-buttons {
			button {
				color: $body-color;

				&.active {
					color: $white;
				}
			}
		}
	}
}