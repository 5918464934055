.header {
	position: relative;
	padding: 20px 0;
	background: $header-bg;
	color: $header-color;
	font-weight: 400;

	.header-grid {
		justify-content: space-between;
	}


	a {
		text-decoration: none;
		color: $header-color;
	}

	.title {

		h2 {
			font-size: 1.125rem;
			font-weight: normal;
			line-height: 0;
			margin: 0;
			padding: 0;

			img {
				width: 110px;
			}
		}

		p {
			margin: 0;
		}

	}

	.subtitle {
		p {
			margin: 0;
		}
	}

	.menu-list {
		margin: 0;
		width: auto !important;
		flex-grow: 1;
		padding: 0!important;
		align-items: center;

		ul {
			padding: 0;
			display: flex;
			justify-content: flex-end;
			width: 100%;
			list-style: none;
			margin: 0;

			li {
				margin: 0 0 0 60px;

				&.active a {
					color: $header-active-color;
				}

				&.logout {
					svg {
						margin-left: 15px;
						margin-bottom: -5px;
					}
				}
			}

			a {
				padding: 0 20px;
				cursor: pointer;

				&:hover {
					color: $header-active-color;
				}
			}
		}

		&__hamburger {
			color: white;

			svg {
				width: 1.5rem;
				height: 1.5rem;
			}
		}

		&__primary {
			display: flex;
			justify-content: flex-end;
		}

	}

	&--dashboard {
		border-bottom: 1px solid $border-color;

		.menu-list ul {

			li {
				&.active a {
					color: $header-active-color;
				}
			}

			a {
				color: $header-color;
				cursor: pointer;

				&:hover {
					color: $header-active-color;
				}
			}
		}
	}
}

.menu-list {
	&__drawer {

		list-style: none;
		margin: 0;
		width: 250px;
		padding: 20px 0;

		li {
			padding: 14px 20px;
			font-size: 1rem;

			a {
				text-decoration: none;
				font-weight: normal;
				display: flex;
				justify-content: space-between;
			}
		}

	}
}


//.app-main {
//
//	.header {
//
//
//		&--dashboard {
//			.menu-list ul {
//
//				li {
//					&.active a {
//						color: $app-header-color;
//					}
//				}
//
//				a {
//					color: $app-header-color;
//
//					&:hover {
//						color: $app-header-color;
//					}
//				}
//			}
//		}
//	}
//
//}