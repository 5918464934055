main {
	width: 100%;
}

.app-main {

	display: flex;
	flex-direction: column;
	min-height: 100vh;

	.app-window {
		flex-grow: 1;
		overflow: hidden;

		.app-container {
			padding: 30px 0;
		}

	}

	.grid-fixer {
		margin: 0 -12px;
	}
}
