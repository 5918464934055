.opening-hours {

	table,
	&__table {
		width: 620px !important;
		margin-top: 20px;
	}

	&__day {
		width: 110px;
	}

	&__input {
		width: 110px;
	}

	&__actions {

	}

}