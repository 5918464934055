@import "../_configs/settings";

@import "variables";

@import "../_configs/settings";

@import "./base/typography";
@import "./base/container";
@import "./base/forms";
@import "./base/table";

@import './components/sections';
@import './components/header';
@import './components/footer';
@import './components/sign-in';
@import './components/spinner';
@import './components/table-listings';
@import './components/view-header';
@import './components/packages';
@import './components/separator';
@import './components/map';
@import './components/modal';
@import './components/opening-hours';
@import './components/pagination';

@import './screens/welcome';
@import './screens/main-app';


/// TO REFACTOR -- PRIYA PLEASE ADD NEW SCSS FILES HERE SO I KNOW WHAT HAS BEEN ADDED
@import './components/upload-field';

.mce-notification-inner {
	display:none !important;
}

.hidden {
	display: none;
}


//@import './components/searchWithButton';

//
//
//@import './components/openingHours';