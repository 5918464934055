.table-listings {

    tr {

        td:first-child {
            text-align: center;
        }

        td:last-child {
            text-align: right;
            min-width: 162px;
        }
    }
}

.data-table {

  &__header {
    width: 100%;
    padding-bottom: 30px;

    &__inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      h4 {
        margin: 0;
      }
    }

  }

}