body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.print-show {
    display: none;
}
/* prettyJSON */
.prettyJSON {
    font-size: 12px;
    word-break: break-word;
}
.prettyJSON div.prettyJSON-group {
    padding-left: 16px;
    position: relative;
}
.prettyJSON div.prettyJSON-label {
    display: inline-block;
    padding: 5px;
    cursor: pointer;
    margin-left: -18px;
}
.prettyJSON div.prettyJSON-label:only-of-type {
    cursor: default;
}
.prettyJSON div.prettyJSON-label ~ div.prettyJSON-group {
    display: none;
}
.prettyJSON div.prettyJSON-label.prettyJSON-open ~ div.prettyJSON-group {
    display: block;
}
.prettyJSON div.prettyJSON-label:before {
    content: '+\00a0';
    font-family: monospace;
}
.prettyJSON div.prettyJSON-label.prettyJSON-open:before {
    content: '-\00a0';
}
.prettyJSON div.prettyJSON-label:only-of-type:before {
    content: '\00a0\00a0';
    cursor: default;
}

@media print {
    html,
    body,
    main {
        overflow: visible !important;
        position: initial !important;
        height: 100% !important;
        display: block !important;
    }

    html,
    table {
        font-size: 10pt;
    }

    .print-show {
        display: block;
    }

    .print-hide {
        display: none;
    }

    .page-break {
        page-break-before: always;
        break-before: page;
    }

    .page-break-avoid {
        page-break-inside: avoid;
        break-inside: avoid-page;
    }

    .remove-nested-flex > div {
        display: block;
    }

    table tbody tr,
    table tbody tr td,
    table tbody tr th,
    table thead tr {
        color: black !important;
        background-color: white !important;
        border-color: black !important;
    }

    table tbody tr td span,
    table tbody tr th span,
    table thead tr *,
    table thead tr * > span {
        color: black !important;
        background-color: white !important;
        border-color: black !important;
    }

    table thead {
        display: table-header-group;
    }

    table tfoot {
        display: table-header-group;
    }
    a {
        color: black !important;
        text-decoration: underline !important;
    }
}
