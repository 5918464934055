body {
	-webkit-font-smoothing: antialiased;
	font-family: 'Circular', '-apple-system', 'BlinkMacSystemFont', 'Roboto', 'Helvetica Neue', 'sans-serif' !important;
	font-size: 14px;
	line-height: 1.7;
}


a {
	color: $link-color;
	text-decoration: underline;
}

h1 {
	font-weight: 400;
	font-size: 32px;
	margin-top: 0;
}

h2 {
	font-size: 28px;
	margin-top: 0;
}

h3 {
	font-size: 24px;
	margin-top: 0;
}


.app-window {
	font-size: 14px;
	line-height: 1.7;


	h1 {
		font-size: 32px;
		font-weight: 300;
		margin-top: 0;
		margin-bottom: 32px;
	}

	h2 {
		font-size: 28px;
		margin-bottom: 28px;
		margin-top: 0;
		font-weight: 300;
	}

	h3 {
		font-size: 24px;
		margin-bottom: 24px;
		margin-top: 0;
		font-weight: 300;
	}

	p {
		margin-bottom: 20px;
		line-height: 1.7;
	}
}