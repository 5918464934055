.sign-in {

    width: 90%;
    max-width: 450px;
    margin: 0 auto;

    &__header {
        padding: 20px 10px;
        text-align: center;

        img {
            max-width: 180px;
            text-align: center;
        }
    }

    &__footer {
        text-align: center;
        padding: 10px 0;
        font-size: 12px;
        color: $welcome-footer-color;

        a {
            color: $welcome-footer-color;
        }
    }

    h3 {
        font-size: 24px;
    }

    .action-buttons {

        .button-flex > button {
            margin-bottom: 0;
        }

        .button-flex > span {
            margin-bottom: 0;
        }

    }

}